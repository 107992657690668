"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
require("./styles/styles.scss");
var ViewerAPI = require("workspace-api");
//#region CORE API
// console.log("TESTING CORE")
// const tidParams = {
//   clientId: "2a15742f-c392-4e61-a661-d5323295b276",
//   clientSecret: "704c77991b8b450fa1365a046aeb262c",
//   appName: 'TekniqConcepts',
//   redirectUri: 'http://localhost:8080',
//   serviceUri: "https://id.trimble.com/",
// };
// console.log("CREDENTIALS TEST")
// console.log("CREDENTIALS TEST")
// const tidCreds = new TC.TIDCredentials(tidParams);
// console.log(tidCreds);
// // initialize the TPaaS TID credentials with initial tokens received from e.g. auth code grant OAuth2 flow
// tidCreds.tokens = {
//   access_token: "string",
//   expires_in: 3600,
//   id_token: "string",
//   refresh_token: "string",
//   scope: "string",
//   token_type: "string",
// };
// // initialize the service credentials with the TID as a root credentials
// const serviceCredentials = new TC.ServiceCredentials(tidCreds);
// console.log(serviceCredentials);
// // configure services clients
// const orgClientEU = new TC.Organizer({
//   credentials: serviceCredentials,
//   region: "eu-west-1",
// });
// console.log(orgClientEU);
// TC.TCPSClient.config.credentials = serviceCredentials;
// // if you need to use different service environments you can specify serviceUri explicitli:
// orgClientEU.config.serviceUri = "https://org-api.trimble.com/v1/";
// TC.TCPSClient.config.serviceUri = "https://app.connect.trimble.com/tc/api/2.0/";
// //console.log("TESTING test()");
// // after this you can use OrganizerClient and TCPSClient normally all token acqusition and refresh will be handled automatically
// // const forestId =123;
// // const treeV0 = await orgClientEU.createTree(forestId, { name: "name", type: "LBS" });
// // const treeV1 = await orgClientEU.updateTree(forestId, treeV0.id, { name: "name2" });
// // const treeV2 = await orgClientEU.deleteTree(forestId, treeV0.id);
// // const tree = await orgClientEU.getTree(forestId, treeV0.id, { deleted: true });
// //testFunction();
// //#endregion
// //#region WEB API
// /** Instantiate extension .
//    *  @param window - Parent window object.
//    *  @param callback - Listen the events with args from the parent.
//    *  @param timeout - Connect timeout in milliseconds.
//    *  @returns TCExtensionAPI - Object with the interaction methods.
// */
// var webAPI: Extensions.TCExtensionAPI;
// connectToWebApi();
// async function connectToWebApi() {
//   webAPI = await Extensions.connect(
//     window.parent,
//     (event, args) => {
//       console.log("did something!?")
//       console.log("event", event)
//       console.log("args", args)
//       console.log("args.data", args.data)
//       console.log("did something!?")
//       console.log("set menu")
//       webAPI.ui.setMenu(mainMenuObject)
//       switch (args.data) {
//         case "submenu_1_clicked":
//           loadMenuPage();
//           break;
//       }
//       switch (event) {
//         case "extension.command":
//           console.log(args.data)
//           //"Command executed by the user: args.data"
//           break;
//         case "extension.accessToken":
//           //"Accestoken or status: args.data"
//           break;
//         case "extension.userSettingsChanged":
//           //"User settings changed!"
//           break;
//         default:
//       }
//     },
//     30000
//   );
// }
// async function loadMenuPage() {
//   console.log("load clicked")
//   console.log("test clicked")
//   testFunction();
//   const test = await webAPI.project.getCurrentProject();
//   console.log(test);
// }
// async function testFunction() {
//   console.log("inside test()");
//   console.log("TRYING SERVERS");
//   const servers = await TC.TCPSClient.listServers();
//   const projects = await TC.TCPSClient.listProjects(servers[0]);
//   console.log("PROJECT");
//   const firstProject = projects[0];
//   console.log(firstProject);
//   console.log("NUMBER OF FILES");
//   const numberOfFiles = firstProject.filesCount;
//   console.log(numberOfFiles);
//   console.log("FILES");
//   const files = await TC.TCPSClient.listFolderEntries(firstProject);
//   console.log(files);
// }
// const mainMenuObject: ExtensionMainMenu = {
//   title: "TIMS MENU",
//   icon: "http://example.com/main_menu_icon.png",
//   command: "main_nav_menu_cliked",
//   subMenus: [
//     {
//       title: "BLA 1",
//       icon: "http://example.com/sub_menu_icon.png",
//       command: "submenu_1_clicked",
//     },
//     {
//       title: "BLA 2",
//       icon: "http://example.com/sub_menu_icon.png",
//       command: "submenu_2_clicked",
//     },
//   ],
// };
// //#endregion
// //#region VIEWER API 
var API;
var viewer = $("#viewerFrame")[0];
var id = 0;
if (inIframe()) {
    viewer.remove();
    connectTo(window.parent);
}
else {
    viewer.src = "https://3d.connect.trimble.com";
    viewer.onload = function () { return connectTo(viewer); };
}
function inIframe() {
    try {
        return window.self !== window.top;
    }
    catch (e) {
        return true;
    }
}
function connectTo(target) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    $("#logText").val(JSON.stringify("Start listener..."));
                    console.log("Start listener...");
                    return [4 /*yield*/, ViewerAPI.connect(target, function (eventName, data) {
                            switch (eventName) {
                                case "viewer.onSelectionChanged":
                                    console.log("viewer.onSelectionChanged");
                                    // const selection: ViewerAPI.Selection = (<SelectionChangedEventArgument>data).data;
                                    // onSelection(selection);
                                    break;
                                case "viewer.onPicked":
                                    console.log("viewer.onPicked");
                                    var picker = data.data;
                                    //onPicked(picker);
                                    break;
                            }
                        })];
                case 1:
                    API = _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
// // document.querySelector("#mainBtn").addEventListener("click", handleButtonClick);
// async function handleButtonClick() {
//   console.log("Main button clicked")
// }
// // document.querySelector("#hideBtn").addEventListener("click", hideButtonClick);
// async function hideButtonClick() {
//   console.log("Hide button clicked")
//   var allModels = await API.viewer.getObjects();
//   var objectIds = allModels[0]?.objects.map(s => s.id)
//   console.log("Create new array", objectIds.length);
//   const neededObjects: number[] = new Array(objectIds.length);
//   console.log("Array created")
//   var selection = await API.viewer.getSelection();
//   for (var index = 0; index < objectIds.length; index++) {
//     var currentId = objectIds[index];
//     var properties = await API.viewer.getObjectProperties(allModels[0].modelId, [currentId]);
//     if (properties[0].class == "IFCPROJECT") {
//       continue;
//     }
//     if (properties[0].class == "IFCBUILDING") {
//       continue;
//     }
//     if (properties[0].class == "IFCBUILDINGSTOREY") {
//       continue;
//     }
//     if (properties[0].class == "IFCSITE") {
//       continue;
//     }
//     var flag = false;
//     if (properties[0]?.properties[0]?.properties[0]?.name != "") {
//       for (const selectedId of selection[0].objectRuntimeIds) {
//         if (currentId == selectedId) {
//           console.log("Hide object", currentId)
//           flag = true;
//           break;
//         }
//       }
//     }
//     if (flag) {
//       continue;
//     }
//     console.log("Add object", currentId)
//     neededObjects[index] = currentId;
//   }
//   console.log(neededObjects);
//   const neededModel: ModelObjectIds = {
//     modelId: allModels[0].modelId,
//     objectRuntimeIds: neededObjects
//   }
//   console.log("Showing neededmodel", neededModel)
//   await API.viewer.setObjectState({
//     modelObjectIds: [neededModel]
//   }, {
//     visible: false
//   });
// }
// // var selection = await API.viewer.getSelection();
// // console.log("asdasdasd", selection)
// // var flag = false;
// // var continueIndex = 0;
// // for (var index = 0; index < objectIds.length; index++) {
// //   flag = false;
// //   var currentId = objectIds[index];
// //   var properties = await API.viewer.getObjectProperties(all[0].modelId, [currentId]);
// //   if (properties[0].class == "IFCPROJECT") {
// //     continue;
// //   }
// //   if (properties[0].class == "IFCBUILDING") {
// //     continue;
// //   }
// //   if (properties[0].class == "IFCBUILDINGSTOREY") {
// //     continue;
// //   }
// //   if (properties[0].class == "IFCSITE") {
// //     continue;
// //   }
// //   if (properties[0]?.properties[0]?.properties[0]?.name != "") {
// //     for (const selectedId of selection[0].objectRuntimeIds) {
// //       if (currentId == selectedId) {
// //         console.log("dont hide", selectedId)
// //         flag = true;
// //         break;
// //       }
// //     }
// //     if (flag) {
// //       console.log("break", currentId)
// //       continueIndex = index;
// //       continue;
// //     }
// //     console.log("hide", currentId)
// //     await API.viewer.setObjectState({
// //       modelObjectIds: [{
// //         modelId: all[0].modelId,
// //         objectRuntimeIds: [currentId]
// //       }]
// //     }, {
// //       visible: false
// //     });
// //   }
// //   console.log("CLass", properties[0].class)
// //   console.log("continue with the next")
// //   console.log("done")
// //}
// //}
document.querySelector("#markBtn").addEventListener("click", markButtonClicked);
function isGeometry(element) {
    return element.name == "CalculatedGeometryValues";
}
function isCenterOfGravity(element, name) {
    console.log(element, name);
    return element.name == name;
}
function markButtonClicked() {
    return __awaiter(this, void 0, void 0, function () {
        var allObjects, _i, allObjects_1, singleObject, _a, _b, objectId, properties, mappedProperties, name, reference, x, y, z;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    console.log("Mark button clicked");
                    return [4 /*yield*/, API.viewer.getSelection()];
                case 1:
                    allObjects = _c.sent();
                    console.log(allObjects);
                    _i = 0, allObjects_1 = allObjects;
                    _c.label = 2;
                case 2:
                    if (!(_i < allObjects_1.length)) return [3 /*break*/, 10];
                    singleObject = allObjects_1[_i];
                    console.log(singleObject);
                    _a = 0, _b = singleObject.objectRuntimeIds;
                    _c.label = 3;
                case 3:
                    if (!(_a < _b.length)) return [3 /*break*/, 9];
                    objectId = _b[_a];
                    console.log(objectId);
                    return [4 /*yield*/, API.viewer.getObjectProperties(singleObject.modelId, [objectId])];
                case 4:
                    properties = _c.sent();
                    mappedProperties = properties[0].properties.flatMap(function (s) { return s.properties; });
                    console.log("ALL PROPERTIES", mappedProperties);
                    name = mappedProperties.find(function (s) { return isCenterOfGravity(s, "NAME"); });
                    reference = mappedProperties.find(function (s) { return isCenterOfGravity(s, "Reference"); });
                    x = mappedProperties.find(function (s) { return isCenterOfGravity(s, "CenterOfGravityX"); });
                    y = mappedProperties.find(function (s) { return isCenterOfGravity(s, "CenterOfGravityY"); });
                    z = mappedProperties.find(function (s) { return isCenterOfGravity(s, "CenterOfGravityZ"); });
                    if (!(x != null && y != null && z != null)) return [3 /*break*/, 7];
                    console.log(reference);
                    console.log(x.value);
                    console.log(y.value);
                    console.log(z.value);
                    if (!name.value.toString().startsWith('B-SMART')) return [3 /*break*/, 6];
                    console.log("reference starts with B");
                    return [4 /*yield*/, API.markup.addTextMarkup([
                            {
                                "color": {
                                    "r": 236,
                                    "g": 0,
                                    "b": 182,
                                    "a": 255
                                },
                                "start": {
                                    "positionX": parseInt(x.value.toString()),
                                    "positionY": parseInt(y.value.toString()),
                                    "positionZ": parseInt(z.value.toString()),
                                    "modelId": "CDqy5xMhD74",
                                    "objectId": 113
                                },
                                "end": {
                                    "positionX": parseInt(x.value.toString()) + 250,
                                    "positionY": parseInt(y.value.toString()) + 250,
                                    "positionZ": parseInt(z.value.toString()) + 500,
                                    "objectId": null
                                },
                                "text": reference.value.toString(),
                            }
                        ])];
                case 5:
                    _c.sent();
                    _c.label = 6;
                case 6: return [3 /*break*/, 8];
                case 7:
                    console.log("MISSING X,Y,Z VALUES");
                    _c.label = 8;
                case 8:
                    _a++;
                    return [3 /*break*/, 3];
                case 9:
                    _i++;
                    return [3 /*break*/, 2];
                case 10: return [2 /*return*/];
            }
        });
    });
}
// // console.log(gdfgdfg)
// // var gdfgdfg = await API.viewer.getObjects({
// //   modelObjectIds: [{
// //     modelId: all[0].modelId,
// //     objectRuntimeIds: [currentId]
// //   }]
// // })
// // console.log(gdfgdfg);
// // console.log("continue with the next")
// // for (var index = continueIndex + 1; index < sfasdasd.length; index++) {
// //   var currentId = sfasdasd[index];
// //   await API.viewer.setObjectState({
// //     modelObjectIds: [{
// //       modelId: all[0].modelId,
// //       objectRuntimeIds: [currentId]
// //     }]
// //   }, {
// //     visible: false
// //   }
// //   )
// // }
// // console.log("done")
// // var flag = false;
// // for (const ss of sfasdasd) {
// //   flag = false;
// //   for (const ff of selection[0].objectRuntimeIds) {
// //     if (ss == ff) {
// //       console.log("dont hide", ff)
// //       flag = true;
// //       break;
// //     }
// //   }
// //   if (flag == false) {
// //     console.log("hide", ss)
// //     await API.viewer.setObjectState({
// //       modelObjectIds: [{
// //         modelId: all[0].modelId,
// //         objectRuntimeIds: [ss]
// //       }]
// //     }, {
// //       color: {
// //         "r": 236,
// //         "g": 0,
// //         "b": 182,
// //         "a": 1
// //       },
// //     }
// //     )
// //   }
// // }
// // console.log("show")
// // for (const bbb of selection[0].objectRuntimeIds) {
// //   await API.viewer.setObjectState({
// //     modelObjectIds: [{
// //       modelId: all[0].modelId,
// //       objectRuntimeIds: [bbb]
// //     }]
// //   }, {
// //     color: "#FC4242"
// //   })
// // }
// // console.log("sssshow")
// //}
// // for (const ss of selection) {
// //   console.log(ss);
// //   for (const asd of ss.objectRuntimeIds) {
// //     await API.viewer.setObjectState({
// //       modelObjectIds: [{
// //         modelId: ss.modelId,
// //         objectRuntimeIds: [asd]
// //       }]
// //     }, {
// //       visible: true
// //     }
// //     )
// //   }
// // }
// // console.log("Asdd", sfasdasd);
// // var allObjects = await API.viewer.getSelection();
// // console.log("Aantal geselecteerde", allObjects);
// // for (const ss of allObjects) {
// //   console.log(ss);
// //   for (const asd of ss.objectRuntimeIds) {
// //     await API.viewer.setObjectState({
// //       modelObjectIds: [{
// //         modelId: ss.modelId,
// //         objectRuntimeIds: [asd]
// //       }]
// //     }, {
// //       visible: false
// //     }
// //     )
// //   }
// // }
// // for (const ss of allObjects) {
// //   console.log(ss);
// //   for (const asd of ss.objectRuntimeIds) {
// //     console.log(asd);
// //     var properties = await API.viewer.getObjectProperties(ss.modelId, [asd]);
// //     if (properties[0]?.properties[0]?.properties[0]?.value == "CONCRETE/Concrete_Undefined") {
// //       var cog = properties[0].properties[1];
// //       var x = cog.properties[2].value;
// //       var y = cog.properties[3].value;
// //       var z = cog.properties[4].value;
// //       console.log(cog.properties[2].value);
// //       console.log(cog.properties[3].value);
// //       console.log(cog.properties[4].value);
// //       await API.markup.addTextMarkup([
// //         {
// //           "color": {
// //             "r": 236,
// //             "g": 0,
// //             "b": 182,
// //             "a": 255
// //           },
// //           "start": {
// //             "positionX": parseInt(x.toString()),
// //             "positionY": parseInt(y.toString()),
// //             "positionZ": parseInt(z.toString()),
// //             "modelId": "CDqy5xMhD74",
// //             "objectId": 113
// //           },
// //           "end": {
// //             "positionX": parseInt(x.toString()) + 250,
// //             "positionY": parseInt(y.toString()) + 250,
// //             "positionZ": parseInt(z.toString()) + 500,
// //             "objectId": null
// //           },
// //           "text": properties[0].product.description
// //         }
// //       ])
// //     }
// //   }
// //   // await API.viewer.setObjectState({
// //   //   modelObjectIds: [{
// //   //     modelId: ss.modelId,
// //   //     objectRuntimeIds: ss.objectRuntimeIds
// //   //   }]
// //   // }, {
// //   //   visible: false
// //   // }
// //   // )
// // }
// // for (const ss of allObjects) {
// //   //console.log(ss.objects[0].product.name);
// //   console.log(ss.objects);
// // }
// // var selectedObjects = await API.viewer.getSelection();
// // $("#logText").val(JSON.stringify(selectedObjects[0]));
// // var asdasd = await API.viewer.getObjectProperties(selectedObjects[0].modelId, selectedObjects[0].objectRuntimeIds);
// // $("#logText").val(JSON.stringify(asdasd[0].properties[6]));
// // var extrusion = asdasd[0].properties[6];
// // //var valuex = extrusion[0].properties.values[0]
// // // var gfg = asdasd[0].properties.find(s => s.properties.find(a => a.name == "Extrusion"))
// // $("#logText").val(JSON.stringify(extrusion));
// // await API.markup.addTextMarkup([
// //   {
// //     "color": {
// //       "r": 236,
// //       "g": 0,
// //       "b": 182,
// //       "a": 255
// //     },
// //     "start": {
// //       "positionX": 6000,
// //       "positionY": 5924.999997019768,
// //       "positionZ": 0.000005960464483090178,
// //       "modelId": "CDqy5xMhD74",
// //       "objectId": 113
// //     },
// //     "end": {
// //       "positionX": 9061.53412845515,
// //       "positionY": 7739.654490570803,
// //       "positionZ": 122.54939136477816,
// //       "objectId": null
// //     },
// //     "text": asdasd[0].product.name
// //   }
// // ])
// //$("#logText").val(JSON.stringify(asdasd));
// // API.markup.addCloudMarkup([
// //   {
// //     "color": {
// //       "r": 236,
// //       "g": 0,
// //       "b": 182,
// //       "a": 255
// //     },
// //     "normal": {
// //       "x": 0,
// //       "y": 0,
// //       "z": 1000
// //     },
// //     "height": 1.0364225594302092,
// //     "width": 1.3818967459069458,
// //     "position": {
// //       "positionX": 4952.993696402456,
// //       "positionY": 9000,
// //       "positionZ": 0
// //     }
// //   }
// // ]);
// // await API.viewer.setObjectState(
// //   {
// //     selected: true
// //   },
// //   {
// //     color: {
// //       a: 0, r: 255, g: 255, b: 255
// //     }
// //   });
// //}
// async function onPicked(picker: ViewerAPI.PointPickedDetail) {
//   $("#logText").val(JSON.stringify(picker));
//   if (picker.position) {
//     var x = picker.position.x * 1000;
//     var y = picker.position.y * 1000;
//     var z = picker.position.z * 1000;
//     await API.viewer.setSelection({
//       modelObjectIds: [{
//         modelId: picker.modelId,
//         objectRuntimeIds: [picker.objectRuntimeId]
//       }]
//     }, "set");
//   }
//   //await API.viewer.setOpacity(10);
//   // await API.viewer.setObjectState(
//   //   {
//   //     modelObjectIds: all
//   //   },
//   //   {
//   //     visible: false,
//   //     color: "reset"
//   //   });
//   // await API.viewer.setObjectState({
//   //   modelObjectIds: [{
//   //     modelId: picker.modelId,
//   //     objectRuntimeIds: [picker.objectRuntimeId]
//   //   }]
//   // }, {
//   //   visible: true
//   // }
//   // )
//   // var properties = await API.viewer.getObjectProperties(picker.modelId, [picker.objectRuntimeId]);
//   // $("#logText").val(JSON.stringify(properties));
//   // console.log(properties);
//   // var selected = API.viewer.getSelection();
//   // await API.markup.addTextMarkup([
//   //   {
//   //     "color": {
//   //       "r": 236,
//   //       "g": 0,
//   //       "b": 182,
//   //       "a": 255
//   //     },
//   //     "start": {
//   //       "positionX": x,
//   //       "positionY": y,
//   //       "positionZ": z,
//   //       "modelId": "CDqy5xMhD74",
//   //       "objectId": 113
//   //     },
//   //     "end": {
//   //       "positionX": x + 500,
//   //       "positionY": y + 500,
//   //       "positionZ": z + 500,
//   //       "objectId": null
//   //     },
//   //     "text": properties[0].product.name
//   //   }
//   // ])
// }
// async function onSelection(selection: ViewerAPI.Selection) {
//   show(selection);
//   const state = {
//     color: {
//       r: 111,
//       g: 111,
//       b: 111,
//       a: 0
//     }
//   }
//   // API.viewer.setObjectState({
//   //   selected: true
//   // }, state);
//   $("#logText").val(JSON.stringify("set opa"));
//   await API.viewer.setOpacity(5);
//   // show("hide all objects");
//   // var bla = await API.viewer.getObjects();
//   // await API.viewer.setObjectState(
//   //   {
//   //     selected: false
//   //   },
//   //   {
//   //     color: {
//   //       a: 0, r: 255, g: 255, b: 255
//   //     }
//   //   });
//   // show("done hiding");
//   // await API.viewer.setObjectState(
//   //   {
//   //     selected: true
//   //   },
//   //   {
//   //     color: {
//   //       a: 255, r: 255, g: 255, b: 255
//   //     }
//   //   });
//   // bla[0].objects.forEach(s => show(s));
//   // console.log("frin evebt", selection, "fro sapi", selectedObjects)
//   // show(bla[0].objects);
//   // var selectedObjects = await API.viewer.getSelection();
//   // console.log("frin evebt", selection, "fro sapi", selectedObjects)
//   // show(selectedObjects);
//   // await API.viewer.setObjectState(
//   //   {
//   //     modelObjectIds: selection
//   //   },
//   //   {
//   //     visible: true,
//   //     color: "reset"
//   //   });
//   // API.viewer.setObjectState({
//   //   selected: true
//   // }, state);
//   // const newView = await API.view.createView(
//   //   {
//   //     description: "asd",
//   //     name: "view description"
//   //   });
//   // API.viewer.setCamera({
//   //   selected: true
//   // });
// }
// async function getHalfObjects() {
//   return (await API.viewer.getObjects()).map((m) => ({
//     modelId: m.modelId,
//     objects: half(m.objects),
//   }));
// }
// function toSelector(modelObjects) {
//   return {
//     modelObjectIds: modelObjects
//       .map((m) => ({
//         modelId: m.modelId,
//         objectRuntimeIds: m.objects.map((o) => o.id).sort(),
//       }))
//       .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0)),
//   };
// }
// function half(target) {
//   return target.splice(0, Math.ceil(target.length / 2));
// }
// function show(content) {
//   $("#logText").val(JSON.stringify(content));
// }
// //#endregion
